import React from 'react';
import { inject } from 'mobx-react';
import { RouterView } from 'mobx-state-router';
import { LoginPage } from './features/login/login-page';
import { HomePage } from './features/home/home-page';
import { Checkout } from './features/buy/checkout';
import Privacy from './features/legal/Privacy';
import Terms from './features/legal/Terms';
import { NotFoundPage } from './features/not-found-page';

const viewMap = {
    login: <LoginPage />,
    home: <HomePage />,
    buy: <Checkout />,
    privacy: <Privacy />,
    terms: <Terms />,
    notFound: <NotFoundPage />
};

export const Shell = inject('rootStore')(
    class extends React.Component {
        render() {
            const { rootStore } = this.props;
            const { routerStore } = rootStore;

            return <RouterView routerStore={routerStore} viewMap={viewMap} />;
        }
    }
);
