import React from 'react';
import { inject } from 'mobx-react';
import TextField from '../../modules/components/TextField';
import Button from '../../modules/components/Button';
import Header from '../../modules/views/Header';
import Typography from '../../modules/components/Typography';

const styles = {
    root: {
        padding: 16,
        justifyContent: 'center',
        position: 'absolute',
        left: '50%',
        top: '20%',
        transform: 'translate(-50%, -20%)'
    }
};

export const LoginPage = inject('rootStore')(
    class extends React.Component {
        state = {
            password: ''
        };
        render() {
            return (
                <React.Fragment>
                    <Header />
                    <div style={styles.root}>
                        <Typography variant="h4" component="h2">
                            Login
                        </Typography>
                        <form onSubmit={this.handleSubmit}>
                            <TextField
                                placeholder="Password"
                                value={this.state.password}
                                onChange={e =>
                                    this.setState({ password: e.target.value })
                                }
                            />
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                            >
                                Submit
                            </Button>
                        </form>
                    </div>
                </React.Fragment>
            );
        }

        handleSubmit = () => {
            const { rootStore } = this.props;
            // TODO: if password is correct, go home; if not, go to not-found
            if (this.state.password === 'flyingfanatic') {
                rootStore.routerStore.goTo('home');
            } else {
                rootStore.routerStore.goTo('notFound');
            }
        };
    }
);
