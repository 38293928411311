import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import LayoutBody from '../components/LayoutBody';
import Typography from '../components/Typography';

const styles = theme => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.secondary.light
    },
    layoutBody: {
        marginTop: theme.spacing.unit * 8,
        marginBottom: theme.spacing.unit * 8,
        display: 'flex'
    },
    iconsWrapper: {
        height: 120
    },
    icons: {
        display: 'flex'
    },
    icon: {
        width: 48,
        height: 48,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.warning.main,
        marginRight: theme.spacing.unit,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark
        }
    },
    list: {
        margin: 0,
        listStyle: 'none',
        paddingLeft: 0
    },
    listItem: {
        paddingTop: theme.spacing.unit / 2,
        paddingBottom: theme.spacing.unit / 2
    },
    language: {
        marginTop: theme.spacing.unit,
        width: 150
    }
});

function Footer(props) {
    const { classes } = props;

    return (
        <Typography component="footer" className={classes.root}>
            <LayoutBody className={classes.layoutBody} width="large">
                <Grid container spacing={40}>
                    <Grid item xs={6} sm={4} md={2}>
                        <Grid
                            container
                            direction="column"
                            justify="flex-end"
                            className={classes.iconsWrapper}
                            spacing={16}
                        >
                            <Grid item className={classes.icons}>
                                <a
                                    href="https://material-ui.com/"
                                    className={classes.icon}
                                >
                                    <img
                                        src="/static/themes/onepirate/appFooterFacebook.png"
                                        alt="Facebook"
                                    />
                                </a>
                                <a
                                    href="https://twitter.com/MaterialUI"
                                    className={classes.icon}
                                >
                                    <img
                                        src="/static/themes/onepirate/appFooterTwitter.png"
                                        alt="Twitter"
                                    />
                                </a>
                            </Grid>
                            <Grid item>© 2018-9 Vivek Bhatia</Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={4} md={2}>
                        <Typography variant="h6" marked="left" gutterBottom>
                            About
                        </Typography>
                        <Typography variant="caption">
                            Wingtracer was created to help you commemorate your
                            own journeys around the world.
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4} md={2}>
                        <Typography variant="h6" marked="left" gutterBottom>
                            Legal
                        </Typography>
                        <ul className={classes.list}>
                            <li className={classes.listItem}>
                                <Link href="/terms">Terms</Link>
                            </li>
                            <li className={classes.listItem}>
                                <Link href="/privacy">Privacy</Link>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">
                            {'Icons made by '}
                            <Link href="http://www.freepik.com" title="Freepik">
                                Freepik
                            </Link>
                            {' from '}
                            <Link
                                href="https://www.flaticon.com/"
                                title="Flaticon"
                            >
                                www.flaticon.com
                            </Link>
                            {' is licensed by '}
                            <Link
                                href="http://creativecommons.org/licenses/by/3.0/"
                                title="Creative Commons BY 3.0"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                CC 3.0 BY
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </LayoutBody>
        </Typography>
    );
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    pure,
    withStyles(styles)
)(Footer);
