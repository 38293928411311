import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LayoutBody from '../components/LayoutBody';
import Typography from '../components/Typography';

const styles = theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: theme.palette.secondary.light
    },
    layoutBody: {
        marginTop: theme.spacing.unit * 15,
        marginBottom: theme.spacing.unit * 30,
        display: 'flex',
        position: 'relative'
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `0px ${theme.spacing.unit * 5}px`
    },
    image: {
        height: 55
    },
    title: {
        marginTop: theme.spacing.unit * 5,
        marginBottom: theme.spacing.unit * 5
    },
    curvyLines: {
        pointerEvents: 'none',
        position: 'absolute',
        top: -180
    }
});

function ProductValues(props) {
    const { classes } = props;

    return (
        <section className={classes.root}>
            <LayoutBody className={classes.layoutBody} width="large">
                <img
                    src="/static/themes/onepirate/productCurvyLines.png"
                    className={classes.curvyLines}
                    alt="curvy lines"
                />
                <Grid container spacing={40}>
                    <Grid item xs={12} md={4}>
                        <div className={classes.item}>
                            <img
                                className={classes.image}
                                src="/static/themes/onepirate/productValues1.svg"
                                alt="suitcase"
                            />
                            <Typography variant="h6" className={classes.title}>
                                Custom art
                            </Typography>
                            <Typography variant="h5">
                                Turn your travel into custom art based on data
                                from your United account. Your personal travel
                                map art comes in a beautiful, acrylic frame you
                                can mount on the wall.
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.item}>
                            <img
                                className={classes.image}
                                src="/static/themes/onepirate/productValues2.svg"
                                alt="graph"
                            />
                            <Typography variant="h6" className={classes.title}>
                                Travel insights
                            </Typography>
                            <Typography variant="h5">
                                {
                                    'Analyze your flight stats to get information about '
                                }
                                {
                                    "how many times you've been around the world or how many cities you've visited."
                                }
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.item}>
                            <img
                                className={classes.image}
                                src="/static/themes/onepirate/productValues3.svg"
                                alt="clock"
                            />
                            <Typography variant="h6" className={classes.title}>
                                Stack up
                            </Typography>
                            <Typography variant="h5">
                                {
                                    'See how your travel stacks up against your friends and '
                                }
                                {
                                    'the community - how did your travels compare?'
                                }
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </LayoutBody>
        </section>
    );
}

ProductValues.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductValues);
