import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Field, Form, Formik } from 'formik';
import { action, decorate } from 'mobx';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {
    FormActions,
    Select,
    TextInput,
    NextButton,
    PrevButton
} from '../../modules/form';
import { ArtSizes } from '../../shared/models/order';

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    selectStyle: {
        width: '100%'
    },
    imageGrid: {
        flexGrow: 1
    }
});

const posterImage = '/static/img/poster.png';
const acrylicFrameImage = '/static/img/acrylic frame.png';

export class ArtPickerFormBase extends React.Component {
    static propTypes = {
        order: PropTypes.object.isRequired,
        onPrev: PropTypes.func.isRequired,
        onNext: PropTypes.func.isRequired
    };

    render() {
        const { classes, order, onPrev } = this.props;

        const validationSchema = yup.object().shape({
            artSize: yup.string().required()
        });

        return (
            <Formik
                initialValues={order}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    this.handleSave(values);
                    setSubmitting(false);
                }}
                render={({ submitForm }) => (
                    <Form className={classes.form}>
                        <Typography variant="h6" gutterBottom>
                            What's your favorite type of print?
                        </Typography>
                        <Typography variant="body1">
                            In the future, we'd like to offer physical prints of
                            your travel map. Given the choices below at the
                            listed prices, which art would you most likely
                            purchase? See the examples below for reference and
                            leave any additional thoughts in the comments box.
                        </Typography>
                        <Field
                            name="artSize"
                            component={Select}
                            label="Art Size"
                            options={ArtSizes}
                            className={classes.selectStyle}
                        />
                        <Field
                            name="comments"
                            component={TextInput}
                            placeholder="Any comments or other ways you would want this printed?"
                            className={classes.selectStyle}
                        />
                        <br />
                        <Grid
                            container
                            className={classes.imageGrid}
                            justify="center"
                            spacing={24}
                        >
                            <Grid item xs={6}>
                                <img src={posterImage} width={'100%'} alt="" />
                            </Grid>
                            <Grid item xs={6}>
                                <img
                                    src={acrylicFrameImage}
                                    width={'100%'}
                                    alt=""
                                />
                            </Grid>
                        </Grid>
                        <FormActions>
                            <PrevButton onClick={onPrev}>Back</PrevButton>
                            <NextButton onClick={submitForm}>
                                Place Order
                            </NextButton>
                        </FormActions>
                    </Form>
                )}
            />
        );
    }

    handleSave = values => {
        const { order, onNext } = this.props;

        const { artSize, comments } = values;
        order.artSize = artSize;
        order.comments = comments;

        onNext();
    };
}

decorate(ArtPickerFormBase, {
    handleSave: action
});

export const ArtPickerForm = withStyles(styles)(ArtPickerFormBase);
