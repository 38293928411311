import React from 'react';
import Typography from '@material-ui/core/Typography';
import { DropzoneArea } from 'material-ui-dropzone';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { FormActions, NextButton } from '../../modules/form';

const uploadStep1Image = '/static/img/unitedData-step1.png';
const uploadStep2Image = '/static/img/unitedData-step2.png';

export class UploadFormBase extends React.Component {
    static propTypes = {
        order: PropTypes.object.isRequired,
        onNext: PropTypes.func.isRequired
    };

    render() {
        const { order, onNext } = this.props;

        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Upload flight data
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Upload your flight data from your United MileagePlus account
                    using the following steps:
                    <ol>
                        <li>
                            Login to your{' '}
                            <a
                                href="https://www.united.com/en/us/account"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                MileagePlus account
                            </a>
                        </li>
                        <li>
                            Click "View full activity details"
                            <img
                                style={{ width: '70%' }}
                                src={uploadStep1Image}
                                alt=""
                            />
                        </li>
                        <li>
                            Click "Download activity" and upload this file below
                            <img
                                style={{ width: '70%' }}
                                src={uploadStep2Image}
                                alt=""
                            />
                        </li>
                    </ol>
                </Typography>
                <DropzoneArea
                    name="csvFileText"
                    onChange={order.setFiles}
                    acceptedFiles={['text/csv']}
                    filesLimit={1}
                    showPreviewsInDropzone={true}
                    maxFileSize={5000000}
                    dropzoneText="Drag and drop a United MileagePlus CSV file here or click"
                />
                <FormActions>
                    <NextButton
                        disabled={order.files.length === 0}
                        onClick={onNext}
                    >
                        Next
                    </NextButton>
                </FormActions>
            </React.Fragment>
        );
    }
}

export const UploadForm = observer(UploadFormBase);
