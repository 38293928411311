import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { action, decorate } from 'mobx';
import * as yup from 'yup';
import {
    FormActions,
    TextInput,
    NextButton,
    PrevButton
} from '../../modules/form';

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    selectStyle: {
        width: '100%'
    }
});

export class FinalizeFormBase extends React.Component {
    static propTypes = {
        onPrev: PropTypes.func.isRequired,
        onNext: PropTypes.func.isRequired
    };

    render() {
        const { classes, order, onPrev } = this.props;

        const validationSchema = yup.object().shape({
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            email: yup
                .string()
                .email()
                .required()
        });

        return (
            <Formik
                initialValues={order}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    this.handleSave(values);
                    setSubmitting(false);
                }}
                render={({ submitForm }) => (
                    <Form className={classes.form}>
                        <Typography variant="h6" gutterBottom>
                            Finalize order
                        </Typography>
                        <Typography variant="body1">
                            We'll process your data and send you a digital copy
                            of your custom-built travel map in approximately 1
                            week. Where should we send it?
                        </Typography>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="firstName"
                                    component={TextInput}
                                    label="First name"
                                    autoComplete="given-name"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="lastName"
                                    component={TextInput}
                                    label="Last name"
                                    autoComplete="family-name"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="email"
                                    component={TextInput}
                                    label="Email Address"
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    name="homeAirport"
                                    component={TextInput}
                                    label="Home Airport"
                                />
                            </Grid>
                            {/*<Grid item xs={12}>
                            <TextField
                              required
                              id="address1"
                              name="address1"
                              label="Address line 1"
                              fullWidth
                              autoComplete="billing address-line1"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="address2"
                              name="address2"
                              label="Address line 2"
                              fullWidth
                              autoComplete="billing address-line2"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              required
                              id="city"
                              name="city"
                              label="City"
                              fullWidth
                              autoComplete="billing address-level2"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField id="state" name="state" label="State/Province/Region" fullWidth />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              required
                              id="zip"
                              name="zip"
                              label="Zip / Postal code"
                              fullWidth
                              autoComplete="billing postal-code"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              required
                              id="country"
                              name="country"
                              label="Country"
                              fullWidth
                              autoComplete="billing country"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
                              label="Use this address for payment details"
                            />
                        </Grid>*/}
                        </Grid>
                        <FormActions>
                            <PrevButton onClick={onPrev}>Back</PrevButton>
                            <NextButton onClick={submitForm}>Next</NextButton>
                        </FormActions>
                    </Form>
                )}
            />
        );
    }

    handleSave = values => {
        const { order, onNext } = this.props;

        const { firstName, lastName, email, homeAirport } = values;
        order.firstName = firstName;
        order.lastName = lastName;
        order.homeAirport = homeAirport;
        order.email = email;

        onNext();
    };
}

decorate(FinalizeFormBase, {
    handleSave: action
});

export const FinalizeForm = withStyles(styles)(FinalizeFormBase);
