import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import AppBar from '../components/AppBar';
import Toolbar, { styles as toolbarStyles } from '../components/Toolbar';
const logoImage = '/static/img/wingtracer-logo.png';

// The header layout with centered title and right-justified buy button is
// inspired by the following StackOverflow answer (see method #2):
// https://stackoverflow.com/questions/38948102/center-and-right-align-flexbox-elements
const styles = theme => ({
    placeholder: toolbarStyles(theme).root,
    toolbar: {
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        fontSize: 24
    },
    hiddenBuyButton: {
        fontSize: 16,
        color: theme.palette.secondary.main,
        marginRight: 'auto',
        visibility: 'hidden'
    },
    buyButton: {
        fontSize: 16,
        color: theme.palette.secondary.main,
        marginLeft: 'auto'
    }
});

function HiddenBuyNowButton(props) {
    const { classes } = props;
    return (
        <Link variant="h6" underline="none" className={classes.hiddenBuyButton}>
            {'Buy Now'}
        </Link>
    );
}

function BuyNowButton(props) {
    const { classes } = props;
    return (
        <Link
            variant="h6"
            underline="none"
            className={classes.buyButton}
            href="/buy"
        >
            {'Buy Now'}
        </Link>
    );
}

function Header(props) {
    const { classes, displayBuyButton } = props;

    return (
        <div>
            <AppBar position="fixed">
                <Toolbar className={classes.toolbar}>
                    {displayBuyButton && (
                        <HiddenBuyNowButton classes={classes} />
                    )}
                    <Link
                        variant="h6"
                        underline="none"
                        color="inherit"
                        className={classes.title}
                        href="/"
                    >
                        <img src={logoImage} alt="" />
                    </Link>
                    {displayBuyButton && <BuyNowButton classes={classes} />}
                </Toolbar>
            </AppBar>
            <div className={classes.placeholder} />
        </div>
    );
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    displayBuyButton: PropTypes.bool
};

export default withStyles(styles)(Header);
