import withRoot from '../../modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import Markdown from '../../modules/components/Markdown';
import Typography from '../../modules/components/Typography';
import LayoutBody from '../../modules/components/LayoutBody';
import Header from '../../modules/views/Header';
import terms from '../../modules/views/terms.md';
import Footer from '../../modules/views/Footer';

class Terms extends React.Component {
    state = {
        content: null
    };

    componentDidMount() {
        fetch(terms)
            .then(response => response.text())
            .then(text => {
                this.setState({
                    content: text
                });
            });
    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <LayoutBody margin marginBottom>
                    <Typography
                        variant="h3"
                        gutterBottom
                        marked="center"
                        align="center"
                    >
                        Terms
                    </Typography>
                    {this.state.content && (
                        <Markdown>{this.state.content}</Markdown>
                    )}
                </LayoutBody>
                <Footer />
            </React.Fragment>
        );
    }
}

export default withRoot(Terms);
