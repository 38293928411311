import firebase from 'firebase/app';
import 'firebase/firestore';

export class FirestoreService {
    db;

    constructor() {
        this.db = firebase.firestore();
    }

    // Creates an order and returns the order id
    createOrder(order) {
        const user = firebase.auth().currentUser;

        return this.db
            .collection('orders')
            .add({
                ...order,
                owner: user ? user.uid : undefined,
                createdAt: firebase.firestore.Timestamp.fromDate(new Date())
            })
            .then(docRef => docRef.id);
    }
}
