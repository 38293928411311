import { action, decorate, observable } from 'mobx';

export const ArtSizes = [
    { id: '24x36', name: 'Poster, 24x36 ($99)' },
    { id: '16x8', name: 'Acrylic Frame, 16x8 ($149)' },
    { id: '30x15', name: 'Acrylic Frame, 30x15 ($199)' },
    { id: '40x20', name: 'Acrylic Frame, 40x20  ($249)' }
];

export const Status = {
    new: {
        id: 'new',
        name: 'New'
    },
    completed: {
        id: 'completed',
        name: 'Completed'
    },
    error: {
        id: 'error',
        name: 'Error'
    }
};

export class Order {
    id;
    files = [];
    csvFileText = '';
    homeAirport = '';
    artSize = '';
    comments = '';
    email = '';
    firstName = '';
    lastName = '';
    status = Status.new.id;

    setFiles = files => {
        this.files = files;

        if (files.length === 0) {
            this.csvFileText = '';
        } else {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = () => {
                this.setCsvFileText(reader.result);
            };
            reader.readAsText(file);
        }
    };

    setCsvFileText = text => {
        this.csvFileText = text;
    };
}

decorate(Order, {
    id: observable,
    files: observable.ref,
    csvFileText: observable,
    homeAirport: observable,
    artSize: observable,
    comments: observable,
    email: observable,
    firstName: observable,
    lastName: observable,
    status: observable,
    setFiles: action,
    setCsvFileText: action
});
