import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { ArtPickerForm } from './artpicker-form';
import { UploadForm } from './upload-form';
import { FinalizeForm } from './finalize-form';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import AppForm from '../../modules/views/AppForm';
import Header from '../../modules/views/Header';
import ReactGA from 'react-ga';

const styles = theme => ({
    stepper: {
        padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit
    }
});

const steps = [
    'Upload flight data',
    'Finalize order',
    'Vote on future offerings'
];

class CheckoutBase extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        rootStore: PropTypes.object.isRequired
    };

    state = {
        activeStep: 0
    };

    constructor(props) {
        super(props);
        props.rootStore.orderStore.createOrder();
    }

    logStepCompletionInGA = stepName => {
        ReactGA.event({
            category: 'User',
            action: stepName
        });
    };

    handlePrev = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1
        }));
        this.logStepCompletionInGA(
            'Moved back from ' + steps[this.state.activeStep]
        );
    };

    handleNext = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1
        }));

        this.logStepCompletionInGA(steps[this.state.activeStep]);
    };

    handleOrderFinalized = () => {
        const { rootStore } = this.props;

        return rootStore.orderStore
            .saveOrder()
            .then(orderId => {
                console.log('Order saved:', orderId);
                this.logStepCompletionInGA('Order submitted');

                this.handleNext();
            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {
        const { classes, rootStore } = this.props;
        const { activeStep } = this.state;
        const order = rootStore.orderStore.order;
        order.homeAirport = 'SFO';

        return (
            <React.Fragment>
                <Header />
                <AppForm>
                    <Typography component="h1" variant="h4" align="center">
                        Checkout
                    </Typography>

                    <Stepper
                        activeStep={activeStep}
                        className={classes.stepper}
                    >
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    {activeStep === 0 && (
                        <UploadForm order={order} onNext={this.handleNext} />
                    )}

                    {activeStep === 1 && (
                        <FinalizeForm
                            order={order}
                            onPrev={this.handlePrev}
                            onNext={this.handleNext}
                        />
                    )}

                    {activeStep === 2 && (
                        <ArtPickerForm
                            order={order}
                            onPrev={this.handlePrev}
                            onNext={this.handleOrderFinalized}
                        />
                    )}

                    {activeStep === 3 && (
                        <React.Fragment>
                            <Typography variant="h5" gutterBottom>
                                Thank you for your order.
                            </Typography>
                            <Typography variant="body1">
                                We will send you an update when your order has
                                shipped.
                            </Typography>
                            <br />
                            <Button
                                variant="contained"
                                component={linkProps => (
                                    <Link
                                        {...linkProps}
                                        href="/home"
                                        variant="button"
                                    />
                                )}
                            >
                                Home
                            </Button>
                        </React.Fragment>
                    )}
                </AppForm>
            </React.Fragment>
        );
    }
}

export const Checkout = inject('rootStore')(
    withStyles(styles)(observer(CheckoutBase))
);
