import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    button: {
        marginLeft: theme.spacing.unit
    }
});

function PrevButtonBase(props) {
    const { classes, children, ...others } = props;
    return (
        <Button className={classes.button} {...others}>
            {children}
        </Button>
    );
}

export const PrevButton = withStyles(styles)(PrevButtonBase);

function NextButtonBase(props) {
    const { classes, children, ...others } = props;
    return (
        <Button
            variant="contained"
            color="primary"
            className={classes.button}
            {...others}
        >
            {children}
        </Button>
    );
}

export const NextButton = withStyles(styles)(NextButtonBase);
