import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import { capitalize } from '@material-ui/core/utils/helpers';

const styles = theme => ({
    root: {
        padding: 0,
        'label + &': {
            marginTop: theme.spacing.unit * 3
        }
    },
    input: {
        minWidth: theme.spacing.unit * 6,
        backgroundColor: theme.palette.common.white,
        '&$disabled': {
            backgroundColor: theme.palette.divider
        }
    },
    inputBorder: {
        border: '1px solid #e9ddd0',
        '&:focus': {
            borderColor: theme.palette.secondary.main
        }
    },
    disabled: {},
    inputSizeSmall: {
        fontSize: 14,
        padding: theme.spacing.unit,
        width: `calc(100% - ${theme.spacing.unit * 2}px)`
    },
    inputSizeMedium: {
        fontSize: 16,
        padding: theme.spacing.unit * 2,
        width: `calc(100% - ${theme.spacing.unit * 4}px)`
    },
    inputSizeLarge: {
        fontSize: 18,
        padding: 22,
        width: `calc(100% - ${22 * 2}px)`
    },
    inputSizeXlarge: {
        fontSize: 20,
        padding: 25,
        width: `calc(100% - ${25 * 2}px)`
    },
    formLabel: {
        fontSize: 18
    },
    select: {
        height: 'auto',
        borderRadius: 0
    },
    selectIcon: {
        top: '50%',
        marginTop: -12
    }
});

function TextField(props) {
    const {
        classes,
        InputProps: {
            classes: {
                input: InputPropsClassesInput,
                ...InputPropsClassesOther
            } = {},
            ...InputPropsOther
        } = {},
        InputLabelProps,
        noBorder,
        size,
        SelectProps,
        ...other
    } = props;

    return (
        <MuiTextField
            InputProps={{
                disableUnderline: true,
                classes: {
                    root: classes.root,
                    input: classNames(
                        classes.input,
                        classes[`inputSize${capitalize(size)}`],
                        {
                            [classes.inputBorder]: !noBorder
                        },
                        InputPropsClassesInput
                    ),
                    disabled: classes.disabled,
                    ...InputPropsClassesOther
                },
                ...InputPropsOther
            }}
            InputLabelProps={{
                ...InputLabelProps,
                shrink: true,
                className: classes.formLabel
            }}
            SelectProps={{
                ...SelectProps,
                classes: {
                    select: classes.select,
                    icon: classes.selectIcon
                }
            }}
            {...other}
        />
    );
}

TextField.propTypes = {
    classes: PropTypes.object.isRequired,
    InputLabelProps: PropTypes.object,
    InputProps: PropTypes.object,
    noBorder: PropTypes.bool,
    SelectProps: PropTypes.object,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge'])
};

TextField.defaultProps = {
    noBorder: false,
    size: 'medium'
};

export default withStyles(styles)(TextField);
