import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import LayoutBody from '../components/LayoutBody';
import Typography from '../components/Typography';
import Link from '@material-ui/core/Link';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing.unit * 9,
        marginBottom: theme.spacing.unit * 9
    },
    button: {
        border: '4px solid currentColor',
        borderRadius: 0,
        height: 'auto',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 5}px`
    },
    link: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3
    },
    buoy: {
        width: 60
    }
});

function ProductSmokingHero(props) {
    const { classes } = props;

    return (
        <LayoutBody className={classes.root} component="section">
            <Button
                className={classes.button}
                component={linkProps => (
                    <Link
                        {...linkProps}
                        href="mailto:vivek@wingtracer.com"
                        target="_blank"
                        variant="button"
                    />
                )}
            >
                <Typography variant="h4" component="span">
                    Got any questions? Need help?
                </Typography>
            </Button>
            <Typography variant="subtitle1" className={classes.link}>
                We are here to help. Get in touch!
            </Typography>
            <img
                src="/static/themes/onepirate/producBuoy.svg"
                className={classes.buoy}
                alt="buoy"
            />
        </LayoutBody>
    );
}

ProductSmokingHero.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductSmokingHero);
