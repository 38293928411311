import React from 'react';
import { inject } from 'mobx-react';

import ProductSmokingHero from '../../modules/views/ProductSmokingHero';
import Footer from '../../modules/views/Footer';
import ProductHero from '../../modules/views/ProductHero';
import ProductValues from '../../modules/views/ProductValues';
import ProductHowItWorks from '../../modules/views/ProductHowItWorks';
import ProductCTA from '../../modules/views/ProductCTA';
import Header from '../../modules/views/Header';

export const HomePage = inject('rootStore')(
    class extends React.Component {
        render() {
            return (
                <React.Fragment>
                    <Header displayBuyButton={true} />
                    <ProductHero />
                    <ProductValues />
                    <ProductHowItWorks />
                    <ProductCTA />
                    <ProductSmokingHero />
                    <Footer />
                </React.Fragment>
            );
        }

        handleClick = () => {
            const { rootStore } = this.props;
            rootStore.routerStore.goTo('buy');
        };
    }
);
