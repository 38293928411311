import { RouterState, RouterStore } from 'mobx-state-router';
import { OrderStore } from './order.store';
import { FirestoreService } from '../services/firestore.service';
import { routes } from './routes';

const notFound = new RouterState('notFound');

export class RootStore {
    routerStore = new RouterStore(this, routes, notFound);
    orderStore = new OrderStore(this);

    services = {
        firestoreService: new FirestoreService()
    };
}
