import { action, decorate, observable } from 'mobx';
import { Order } from '../models/order';

export class OrderStore {
    rootStore;
    order;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    createOrder() {
        this.order = new Order();
    }

    saveOrder() {
        const { firestoreService } = this.rootStore.services;

        // Remove id and files before saving
        const { id, files, ...rest } = this.order;
        return firestoreService.createOrder(rest);
    }
}

decorate(OrderStore, {
    order: observable,
    createOrder: action
});
