import firebase from 'firebase/app';
import 'firebase/auth';
import ReactGA from 'react-ga';
import { history } from './shared/utils/history';

const firebaseConfig = {
    apiKey: 'AIzaSyC2DH54Rxt4MO9rC2HcDC-Bh8XigSRhLbg',
    authDomain: 'wingtracer.firebaseapp.com',
    databaseURL: 'https://wingtracer.firebaseio.com',
    projectId: 'wingtracer',
    storageBucket: 'wingtracer.appspot.com',
    messagingSenderId: '971177242289'
};

export function initFirebase() {
    firebase.initializeApp(firebaseConfig);
    firebase
        .auth()
        .signInAnonymously()
        .catch(function(error) {
            console.log(`${error.code}: ${error.message}`);
        });
}

export function initializeReactGA() {
    ReactGA.initialize('UA-132709282-1');

    // Set up a history listener
    const historyListener = location => {
        ReactGA.pageview(location.pathname);
    };
    history.listen(historyListener);

    // The listener is listening for history changes - it won't fire on page load.
    // Hence fire it manually on first page load.
    historyListener(window.location);
}
